import { Record, List } from 'immutable'

const LoginRecord = Record({
  email: null,
  password: null,
  passwordValid: true,
  companies: List(),
  selectedCompany: null,
  source: null,
  data: {},
  token: null,
  force_saml: false
})

const initialState = new LoginRecord()

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INITIALIZE_DATA':
    case 'AUTHENTICATION_SUCCESSFULL':
    case 'COMPANIES_FETCH_SUCCESS':
    case 'SELECTED_COMPANY':
    case 'RESET':
      return state.merge(action.payload)
    default:
      return state
  }
}

export default loginReducer
