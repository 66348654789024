// Import dependencies
import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import rootReducer from './reducers/index'

// Define middlewares array
const middlewares = [
  thunkMiddleware
]

// Apply middleware
let middleware = applyMiddleware(...middlewares)

// Optionally integrate Redux DevTools Extension
if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  middleware = compose(middleware, window.__REDUX_DEVTOOLS_EXTENSION__())
}

// Create store
const store = createStore(rootReducer, {}, middleware)

// Export the store
export default store
