// Polyfills for older and crappy browsers
import 'core-js'

import 'shared/utils/i18n'

import { Promise } from 'bluebird'

// Bluebird config
Promise.config({
  warnings: false
})

Promise.onPossiblyUnhandledRejection((error) => {
  if (error && error.message !== 'requestError') {
    throw error
  }
})
