// React
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect as reduxConnect } from 'react-redux'
import uiConnect from 'shared/redux/interface'
import store from 'login/redux/store'

// Libraries
import { withTranslation } from 'shared/utils/withTranslation'

// Components
import Button from 'ui/components/Button'
import Link from 'ui/components/Link'

// Actions
import LoginActions from 'login/redux/actions/login'

const Login = ({
  initialEmail,
  updateUI,
  ui,
  email,
  companies,
  selectedCompany,
  t,
  onChange,
  onEmailKeypress,
  onEmailBlur,
  nextStep,
  selectCompany,
  lookupByEmail
}) => {
  useEffect(() => {
    if (initialEmail) {
      updateUI({ email: initialEmail })
      lookupByEmail(false, initialEmail)
    }
  }, [initialEmail, updateUI, lookupByEmail])

  return (
    <div>
      <div className="form-content">
        <h2 className="form-content__title">{t('login.title')}</h2>

        <div className="form">
          <div className="form__field">
            <label className="form__label">{t('login.email')}</label>
            <input
              type="email"
              name="email"
              value={ui.email}
              onChange={(e) => onChange('email', e)}
              onKeyPress={onEmailKeypress}
              onBlur={onEmailBlur}
              autoFocus
            />

            {ui.loadingCompanies && (
              <div className="loading-wrapper">
                <div className="bq-loader" />
              </div>
            )}
          </div>

          {companies.size > 0 && (
            <div className="form__field">
              <div className="list-group company-select">
                <hr />
                <label className="form__label">{t('login.select_your_company')}</label>
                {companies.map((company) => {
                  const selected = selectedCompany === company.slug

                  return (
                    <li
                      key={company.slug}
                      onClick={() => selectCompany(selected ? null : company)}
                      className={`${selected ? 'selected' : ''} list-group-item`}
                    >
                      <div className="list-group-item-heading">
                        <b style={{ fontWeight: 700 }}>{company.name}</b>
                      </div>
                      <div className="list-group-item-text">{`https://${company.slug}.booqable.com`}</div>
                      {selected && ui.loadingAuthenticated && (
                        <div className="loading-wrapper">
                          <div className="bq-loader blue-bg" />
                        </div>
                      )}
                    </li>
                  )
                })}
              </div>
            </div>
          )}

          {(!email || companies.size === 0) && (
            <div className="form__field">
              <Button
                block
                color="primary"
                type="submit"
                promise={ui.submitPromise}
                onClick={() => nextStep(email, selectedCompany)}
              >
                {t('common.actions.continue')}
              </Button>
            </div>
          )}
        </div>
      </div>
      <div style={{ padding: '20px' }}>
        {t('login.no_account')}
        {' '}
        <Link href="https://signup.booqable.com/">
          {t('login.signup')}
        </Link>
      </div>
    </div>
  )
}

Login.propTypes = {
  initialEmail: PropTypes.string,
  updateUI: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
  email: PropTypes.string,
  companies: PropTypes.object,
  selectedCompany: PropTypes.string,
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onEmailKeypress: PropTypes.func.isRequired,
  onEmailBlur: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  selectCompany: PropTypes.func.isRequired,
  lookupByEmail: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  email: state.login.email,
  initialEmail: state.login.data?.toJS().email,
  companies: state.login.companies,
  selectedCompany: state.login.selectedCompany
})

const mapDispatchToProps = (_dispatch, props) => {
  const lookupByEmail = (signup = true, email) => {
    props.updateUI({ loadingCompanies: true })

    LoginActions.lookupCompaniesByEmail(email || props.ui.email).then((response) => {
      props.updateUI({ loadingCompanies: false })

      if (signup && response.payload.companies.size === 0) {
        const data = store.getState().login.data?.toJS()
        let params

        if (data?.authenticated_url) {
          data.Source = data.provider
          data.email = email || props.ui.email
          params = Object.keys(data).map((key) => `${key}=${encodeURIComponent(data[key])}`).join('&')
        } else {
          params = `email=${encodeURIComponent(email || props.ui.email)}`
        }

        window.location = `${window.booqableProtocol}://signup.${window.booqableUrl}?${params}`
      }
    })
  }

  const redirect = (company) => {
    const data = store.getState().login.data?.toJS() || {}
    data.company = company
    data.email = props.ui.email

    const url = data?.authenticated_url || `${window.booqableProtocol}://${company}.${window.booqableUrl}`
    const params = Object.keys(data).map((key) => `${key}=${encodeURIComponent(data[key])}`).join('&')

    window.location = `${url}?${params}`
  }

  const redirectToSignInPage = (company) => {
    const data = store.getState().login.data?.toJS()
    company = company || props.ui.selectedCompany

    let redirectTo
    if (data?.authenticated_url) {
      redirectTo = new URL(`${window.booqableProtocol}://connect.${window.booqableUrl}`)
      redirectTo.pathname = data.authenticated_url

      data.company = company.slug
      data.email = props.ui.email

      Object.keys(data).forEach((key) => {
        redirectTo.searchParams.set(key, data[key])
      })
    }

    const uri = new URL(`${window.booqableProtocol}://${company.slug}.${window.booqableUrl}`)
    uri.pathname = '/employees/sign_in'
    uri.searchParams.set('employee[email]', props.ui.email)

    if (redirectTo) {
      uri.searchParams.set('redirect_uri', redirectTo.toString())
    }

    window.location = uri.toString()
  }

  const authenticate = () => {
  }

  return {
    onChange: (field, e) => {
      if (field === 'email') LoginActions.reset()

      const newState = {}
      newState[field] = e.target.value
      props.updateUI(newState)
    },

    onEmailBlur: () => {
      lookupByEmail(false)
    },

    onEmailKeypress: (e) => {
      if (e.key === 'Enter') lookupByEmail()
    },

    lookupByEmail,

    selectCompany: (company) => {
      if (company && company.saml) {
        window.location = `${window.booqableProtocol}://${company.slug}.booqable.com/employees/auth/saml`

        return
      }

      LoginActions.selectCompany(company.slug)
      props.updateUI({ selectedCompany: company.slug })

      if (company) {
        props.updateUI({ loadingAuthenticated: true })

        LoginActions.isAuthenticated(company.slug).then((response) => {
          const email = response.data.employee.email

          if (email === props.ui.email) {
            redirect(company.slug)
          } else {
            redirectToSignInPage(company)
          }
        }).catch(() => {
          redirectToSignInPage(company)
        })
      }
    },

    nextStep: (email, company) => {
      if (email && company) {
        authenticate()
      } else if (email) {
        lookupByEmail()
      }
    }
  }
}

let decoratedComponent = reduxConnect(mapStateToProps, mapDispatchToProps)(Login)

decoratedComponent = uiConnect('Login', {
  state: {
    submitPromise: null,
    email: null,
    loadingCompanies: false,
    loadingAuthenticated: false,
    selectedCompany: null
  }
})(decoratedComponent)

export default withTranslation('login')(decoratedComponent)
