import { combineReducers } from 'redux'
import { reducer as uiConnectReducer } from 'shared/redux/interface'
import loginReducer from './login'

const rootReducer = combineReducers({
  interface: uiConnectReducer,
  login: loginReducer
})

export default rootReducer
